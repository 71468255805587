<template>
    <div class="main">
        <div class="content">
            <div class="my-error">
                404!
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Page404',
        computed: {}
    }
</script>

<style lang="less">
    .main{
		height: 100vh;
        display: flex;
		.content {
            margin: auto;
			.my-error {
                font-size: 5em;
			}
		}
	}

</style>